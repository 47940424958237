export const THE_GRAPH_URL = "https://api.thegraph.com/subgraphs/name/exodiafinance/exodia-subgraph";
export const EPOCH_INTERVAL = 5400;

// NOTE could get this from an outside source since it changes slightly over time
export const BLOCK_RATE_SECONDS = 1.2;

export const TOKEN_DECIMALS = 9;

export const OHM_TICKER = "DGR";
export const sOHM_TICKER = "sDGR";
export const wsOHM_TICKER = "wsDGR";

interface IPoolGraphURLS {
  [index: string]: string;
}

export const POOL_GRAPH_URLS: IPoolGraphURLS = {
  4: "https://api.thegraph.com/subgraphs/name/pooltogether/rinkeby-v3_4_3",
  250: "https://api.thegraph.com/subgraphs/name/pooltogether/pooltogether-v3_4_3",
};

interface IAddresses {
  [key: number]: { [key: string]: string };
}

export const addresses: IAddresses = {
  250: {
    // DAGGERRRRRRRRRRRRRRRR
    DAI_ADDRESS: "0xCa080CA0D175BFA23DC3fff86Fc3C9BAc0fc0179", // OKAY
    OHM_ADDRESS: "0x5a7DB1cace6aaB190153BcD2fB3223a47dc89B54", // OKAY
    STAKING_ADDRESS: "0x54b595CBa8C377f80dF25eAf0bA977d640d9eEde", // The new staking contract // OKAY
    STAKING_HELPER_ADDRESS: "0x6E22D68C4e519a11631b42E543b0F88bbC14c7eE", // Helper contract used for Staking only // OKAY
    SOHM_ADDRESS: "0xd4220EaCAfEdf30FE273d1e014F7A68D095651D8", // OKAY
    DISTRIBUTOR_ADDRESS: "0xd8DA31efE8d83A8ae511D858A93524F14e66dd80", // OKAY
    BONDINGCALC_ADDRESS: "0x01884c8fba9e2c510093d2af308e7a8ba7060b8f", // OKAY
    CIRCULATING_SUPPLY_ADDRESS: "0x571ef9199c3559d2450d509a4bda1127f729d205", // OKAY
    TREASURY_ADDRESS: "0xC105013E25EDF87918094E639bBb45073627D422", // OKAY
    REDEEM_HELPER_ADDRESS: "0x9d1530475b6282Bd92da5628E36052f70C56A208", // OKAY
    PT_TOKEN_ADDRESS: "0x0E930b8610229D74Da0A174626138Deb732cE6e9", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS // xxxIGNORExxx
    PT_PRIZE_POOL_ADDRESS: "0xEaB695A8F5a44f583003A8bC97d677880D528248", // NEW // xxxIGNORExxx
    PT_PRIZE_STRATEGY_ADDRESS: "0xf3d253257167c935f8C62A02AEaeBB24c9c5012a", // NEW // xxxIGNORExxx
  },
  4002: {
    DAI_ADDRESS: "0xA03dafF4dC940392F441d9d26d3BF2e10A1ACC65", // duplicate
    OHM_ADDRESS: "0x7Da746d61Ae1393bd753Cc543C1726e3b9F3BFA3",
    STAKING_ADDRESS: "0x6F7610FBeEcd91E538e49C403634375549Ae7709",
    STAKING_HELPER_ADDRESS: "0x366f46ab933bc8C51400aE01220694d44468C632",
    OLD_STAKING_ADDRESS: "0xb640AA9082ad720c60102489b806E665d67DCE32",
    SOHM_ADDRESS: "0x9B6e916cFb408e168423c896925b78332cf59bfA",
    OLD_SOHM_ADDRESS: "0x8Fc4167B0bdA22cb9890af2dB6cB1B818D6068AE",
    MIGRATE_ADDRESS: "0x3BA7C6346b93DA485e97ba55aec28E8eDd3e33E2",
    DISTRIBUTOR_ADDRESS: "0x074CF244c7C27ec8518fb47434Aca5D156712451",
    BONDINGCALC_ADDRESS: "0x9AdBBC0856Dba701139452B02380602E81F6a864",
    CIRCULATING_SUPPLY_ADDRESS: "0x5b0AA7903FD2EaA16F1462879B71c3cE2cFfE868",
    TREASURY_ADDRESS: "0x4aB7E0918E29BC7dB6719AB51ca615Db37a5B2a3",
    REDEEM_HELPER_ADDRESS: "0xBd35d8b2FDc2b720842DB372f5E419d39B24781f",
    PT_TOKEN_ADDRESS: "0x0a2d026bacc573a8b5a2b049f956bdf8e5256cfd", // 33T token address, taken from `ticket` function on PRIZE_STRATEGY_ADDRESS
    PT_PRIZE_POOL_ADDRESS: "0xf9081132864ed5e4980CFae83bDB122d86619281", // NEW
    PT_PRIZE_STRATEGY_ADDRESS: "0x2Df17EA8D6B68Ec444c9a698315AfB36425dac8b", // NEW
  },
};
